<template>
  <div class="font-source">
    <section>
      <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
        Create an Item
      </h4>

      <div class="px-4 lg:px-20 mt-2.5">
        <label for="categoryCode">
          Select category
          <span class="text-red-400">*</span>
        </label>
        <v-select
          id="categoryCode"
          label="categoryName"
          :reduce="(b) => b.categoryCode"
          :options="categories"
          v-model="form.categoryCode"
          placeholder="-- select category --"
        ></v-select>
      </div>

      <div class="px-4 lg:px-20 mt-2.5">
        <label for="itemName">
          Item name
          <span class="text-red-400">*</span>
        </label>
        <input
          type="text"
          class="input"
          id="itemName"
          v-model="form.itemName"
          placeholder="Enter item name"
          v-uppercase
        />
      </div>

      <div class="px-4 lg:px-20 mt-4">
        <label for="itemCode">
          Item code
          <span class="text-red-400">*</span>
        </label>
        <input
          type="text"
          class="input"
          id="itemCode"
          v-model="form.itemCode"
          placeholder="Enter item code"
          v-uppercase
        />
      </div>
    </section>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        @click="add"
        :disabled="processing || !createReady"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Item</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateItem",

  data() {
    return {
      processing: false,
      form: {
        categoryCode: null,
        itemName: null,
        itemCode: null,
      },
      categories: [],
    };
  },

  computed: {
    createReady() {
      return (
        !!this.form.categoryCode && this.form.itemName && this.form.itemCode
      );
    },
  },

  async created() {
    await this.getCategories();
  },

  methods: {
    async getCategories() {
      try {
        const res = await this.$http.get("/category?limit=100");

        if (!res) {
          return;
        }

        const { data } = res;
        this.categories = data.data.data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
    async add() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }

        this.processing = true;

        const res = await this.$http.post("/revenue/create-revenue", this.form);

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$emit("add", data);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Create an Agent
    </h4>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="agentName">
        Agent name
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="agentName"
        placeholder="Enter agent name"
        v-model="form.name"
      />
    </div>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="email">
        Agent Email
        <span class="text-red-400">*</span>
      </label>
      <input
        type="email"
        class="input"
        id="email"
        placeholder="Enter agent email"
        v-model="form.email"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="companyName">
        Company name
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="companyName"
        placeholder="Enter your company name"
        v-model="form.companyName"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="address">
        Address
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="address"
        placeholder="Enter your business address"
        v-model="form.businessAddress"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="phone">
        Phone number
        <span class="text-red-400">*</span>
      </label>
      <input
        type="number"
        class="input"
        id="phone"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Enter agent phone number"
        v-model="form.phone"
      />
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !createReady"
        @click="addAgent"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Agent</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateAgent",

  data() {
    return {
      processing: false,
      form: {
        phone: null,
        email: null,
        name: null,
        companyName: null,
        businessAddress: null,
        resetUrl: `${window.location.origin}/reset-password`,
      },
    };
  },

  computed: {
    createReady() {
      return (
        !!this.form.phone &&
        this.form.email &&
        this.form.name &&
        this.form.companyName &&
        this.form.businessAddress
      );
    },
  },

  methods: {
    async addAgent() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.post("/auth/create-agent", this.form);

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        // console.log(data.newUser);
        this.$emit("add-agent", data.newUser);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Create a Sub Item
    </h4>

    <div class="px-4 lg:px-20 mt-4">
      <label for="subItemName"> Sub item name </label>
      <input
        type="text"
        class="input"
        id="subItemName"
        v-model="form.subItemName"
        placeholder="Enter sub item name"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="subItemCode"> Sub item code </label>
      <input
        type="text"
        class="input"
        id="subItemCode"
        v-model="form.subItemCode"
        placeholder="Enter sub item code"
      />
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !addReady"
        @click="add"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Sub Item</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateSubItem",

  data() {
    return {
      processing: false,
      form: {
        subItemName: null,
        subItemCode: null,
      },
    };
  },

  props: {
    datt: {
      type: Object,
      default: () => ({
        categoryCode: null,
        itemCode: null,
      }),
    },
  },

  computed: {
    addReady() {
      return (
        this.form.subItemCode &&
        this.form.subItemName &&
        this.datt.categoryCode &&
        this.datt.itemCode
      );
    },
  },

  methods: {
    async add() {
      try {
        if (!this.addReady) {
          this.$swal({
            icon: "error",
            text: "Please fill the form",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.post("/evenue/create-subitem", {
          ...this.form,
          ...this.datt,
        });

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;
        console.log(data);

        this.$swal({
          icon: "success",
          text: "Created successfully",
        });
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>

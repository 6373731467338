<template>
  <div class="font-source">
    <section class="block lg:flex justify-between">
      <div class="text-base lg:text-xl">
        <h4 class="font-semibold text-ansBlack text-base lg:text-xl">
          Dashboard
        </h4>
        <small class="text-ghostWhite mt-0 lg:mt-1 text-base lg:text-xl">
          Welcome Admin
        </small>
      </div>

      <div
        class="flex justify-between gap-5 mt-5 lg:mt-0 w-full lg:w-auto font-semibold text-sm lg:text-base"
      >
        <button
          @click="showCreateItem = true"
          class="bg-ansLemon text-ansGreen px-4 py-3 rounded flex gap-3 items-center w-1/2 lg:w-auto"
        >
          <img src="@/assets/images/icons/plus.svg" alt="icons" />
          <span>Create new Item</span>
        </button>

        <button
          @click="showCreate = true"
          class="bg-ansGreen text-white px-4 py-3 rounded flex gap-3 items-center w-1/2 lg:w-auto"
        >
          <img
            src="@/assets/images/icons/agents.svg"
            alt="icons"
            class="whither"
          />
          <span>Create new agent</span>
        </button>
      </div>
    </section>

    <section
      class="flex flex-col lg:flex-row justify-between mt-5 lg:mt-14 gap-y-4 lg:gap-y-0 gap-x-0 lg:gap-x-[39px] font-source"
    >
      <div class="amt-box">
        <div>Taxes Paid</div>
        <div>{{ totalPaidAmount | toCurrency }}</div>
      </div>

      <div class="amt-box">
        <div>Taxes Created</div>
        <div>{{ totalRevenue }}</div>
      </div>

      <div class="amt-box">
        <div>Number of Agents</div>
        <div>{{ totalAgents }}</div>
      </div>

      <div class="amt-box">
        <div>Generated Invoices</div>
        <div>{{ totalInvoices }}</div>
      </div>
    </section>

    <section
      class="block lg:flex justify-between font-source mt-5 lg:mt-14 pb-10 gap-10"
    >
      <div class="table-box">
        <div class="flex justify-between px-2 mb-4">
          <h4>Recent items created</h4>

          <router-link :to="{ name: 'Admin Revenue Items' }">
            View all
          </router-link>
        </div>

        <my-table
          :table-data="revenues"
          :fields="revfed"
          :show-page="false"
          :show-search="false"
        >
        </my-table>
      </div>

      <div class="table-box">
        <div class="flex justify-between px-2 mb-4">
          <h4>Recent agents created</h4>

          <router-link :to="{ name: 'Admin Agents' }">View all</router-link>
        </div>

        <my-table
          :table-data="agents"
          :fields="agnfed"
          :show-page="false"
          :show-search="false"
        >
        </my-table>
      </div>
    </section>

    <Modal v-if="showCreate" v-model="showCreate">
      <CreateAgent @add-agent="onAddAgent" />
    </Modal>

    <Modal v-if="showCreateItem" v-model="showCreateItem">
      <CreateItem @add="onAddItem" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/app/Modal";
import CreateAgent from "@/components/admin/CreateAgent";
import CreateItem from "@/components/admin/CreateItem";
export default {
  name: "AdminDashboard",

  components: {
    Modal,
    CreateAgent,
    CreateItem,
  },

  data() {
    return {
      getting: true,
      showCreate: false,
      showCreateItem: false,
      agnfed: [
        {
          name: "name",
          title: "Agent name",
          titleClass:
            "w-1/3 text-left pl-4 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-left pl-4 text-xs text-romanSilver",
        },
        {
          name: "createdAt",
          title: "Created",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-center text-xs text-romanSilver",
          formatter: (val) => {
            return this.moment(val).format("DD-MM-YYYY");
          },
        },
      ],
      revfed: [
        {
          name: "itemName",
          title: "Item Title",
          titleClass:
            "w-1/3 text-left pl-4 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass:
            "w-1/3 py-2 text-left pl-4 text-xs text-romanSilver uppercase",
        },
        {
          name: "createdAt",
          title: "Created",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-center text-xs text-romanSilver",
          formatter: (val) => {
            return this.moment(val).format("DD-MM-YYYY");
          },
        },
      ],
      revenues: [],
      agents: [],
      totalPaidAmount: 0,
      totalRevenue: 0,
      totalAgents: 0,
      totalInvoices: 0,
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddAgent() {
      this.showCreate = false;
    },
    onAddItem() {
      this.showCreateItem = false;
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get("/dashboard");
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.revenues = data.data.revenues;
        this.agents = data.data.agents;
        this.totalPaidAmount = data.data.totalPaidAmount;
        this.totalRevenue = data.data.totalRevenue;
        this.totalAgents = data.data.totalAgents;
        this.totalInvoices = data.data.totalInvoices;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.amt-box {
  @apply bg-oldSilver text-cadet rounded-lg w-full lg:w-1/4 flex lg:block justify-between items-center p-5 lg:p-4;
}
.amt-box div:first-child {
  @apply font-semibold;
}
.amt-box div:last-child {
  @apply text-2xl lg:text-4xl font-semibold mt-0 lg:mt-3;
}
.table-box {
  @apply rounded-md py-4 px-2 w-full lg:w-1/2 mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
.table-box > div > a {
  @apply text-ansGreen font-semibold;
}
</style>

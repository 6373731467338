<template>
  <div class="font-source">
    <section class="block lg:flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">Revenues</h4>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of revenues</h4>

        <api-table
          apiUrl="/revenue"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          :bus="bus"
        >
          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-3 py-1.5 text-white bg-blue-500 rounded"
                @click="createSubs(props.rowData)"
              >
                Add Sub
              </button>
              <!-- <button
                class="px-3 py-1.5 text-white bg-ansGreen rounded"
                @click="onAction('edit-item', props.rowData, props.rowIndex)"
              >
                Edit
              </button>
              <button
                class="px-3 py-1.5 text-white bg-cadet rounded"
                @click="onAction('delete-item', props.rowData, props.rowIndex)"
              >
                Disable
              </button> -->
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showCreateSub" v-model="showCreateSub">
      <CreateSubscription
        :revenueCode="revenueCode"
        :durations="durations"
        @add="onAddItem"
      />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal";
import CreateSubscription from "@/components/admin/CreateSubscription";
export default {
  name: "Revenues",

  components: {
    Modal,
    CreateSubscription,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showCreateSub: false,
      revenueCode: null,
      durations: [],
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "revenueName",
          title: "Revenue name",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "revenueCode",
          title: "Revenue code",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} uppercase`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  async created() {
    await this.getDurations();
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddItem() {
      this.showCreateSub = false;
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    createSubs(data) {
      this.revenueCode = data.revenueCode;
      this.showCreateSub = true;
    },
    async getDurations() {
      try {
        const res = await this.$http.get("/duration?limit=100");

        if (!res) {
          this.durations = [];
          return;
        }

        const { data } = res;
        this.durations = data.data.data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>

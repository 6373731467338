<template>
  <div class="font-source">
    <section>
      <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
        Create a Subscription
      </h4>

      <div v-if="!hideRev" class="px-4 lg:px-20 mt-2.5">
        <label for="revenue">Revenue Item</label>
        <v-select
          id="revenue"
          label="revenueName"
          :reduce="(b) => b.revenueCode"
          :options="revenueItems"
          v-model="form.revenueCode"
          :filterable="false"
          @search="onSearch"
          placeholder="-- select revenue --"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No results found for <em>{{ search }}</em
              >.
            </template>
            <span v-else clas="text-gray-400 lowercase text-xs">
              Start typing to search for a revenue
            </span>
          </template>
        </v-select>
      </div>

      <div class="px-4 lg:px-20 mt-4">
        <label for="duration">Duration</label>
        <v-select
          id="duration"
          label="name"
          :reduce="(b) => b.durCode"
          :options="durations"
          v-model="form.durationCode"
          placeholder="-- select duration --"
        ></v-select>
      </div>

      <div class="px-4 lg:px-20 mt-4">
        <label for="amount">
          Amount
          <span class="text-red-400">*</span>
        </label>
        <money-input
          type="text"
          class="input"
          id="amount"
          v-model="form.amount"
          placeholder="Enter amount"
        />
      </div>
    </section>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        @click="add"
        :disabled="processing || !createReady"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Subscription</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateSubscription",

  data() {
    return {
      processing: false,
      hideRev: false,
      form: {
        revenueCode: null,
        durationCode: null,
        amount: null,
      },
      revenueItems: [],
    };
  },

  computed: {
    createReady() {
      return (
        !!this.form.revenueCode && this.form.durationCode && this.form.amount
      );
    },
  },

  props: {
    revenueCode: {
      type: [Number, String],
      required: false,
      default: null,
    },
    durations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  mounted() {
    if (this.revenueCode) {
      this.form.revenueCode = this.revenueCode;
      this.hideRev = true;
    }
  },

  methods: {
    onSearch(search, loading) {
      if (search.length > 2) {
        this.getRevenues(search, loading);
      }
    },
    async getRevenues(search, loading) {
      try {
        loading(true);
        const res = await this.$http.get("/revenue?search=" + search);

        if (!res) {
          this.revenueItems = [];
          return;
        }

        const { data } = res;
        this.revenueItems = data.data.data;
        loading(false);
      } catch (err) {
        loading(false);
        console.log(err);
      }
    },

    async add() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }

        this.processing = true;

        const res = await this.$http.post(
          "/subscription/create-subscription",
          this.form
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$emit("add", data);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
